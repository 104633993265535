<template>
  <div v-loading="loading">
    <back />
    <div class='globle_border content'>
      <header>
        <h3>采购单详情</h3>
        <div class="info">
          <div>商超名称：<span>{{ shopName }}</span></div>
          <div>商超编号：<span>{{ shopNum }}</span></div>
          <div>手机号码：<span>{{ contactTel }}</span></div>
        </div>

      </header>
      <!-- 表格 -->
      <div class="globle_table tableBox">
        <el-table max-height="540" :data="tableData" style="width: 100%">
          <el-table-column label="序号" min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip min-width="120" align="center">
          </el-table-column> -->
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="categoryName" label="商品分类" show-overflow-tooltip min-width="130" align="center">
          </el-table-column>
          <el-table-column prop="brand" label="商品品牌" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="specs" label="规格" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="supplyPrice" label="采购价格(元)" show-overflow-tooltip min-width="130" align="center">
          </el-table-column>
          <!-- <el-table-column prop="purchaseCount" label="订单数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column> -->
          <el-table-column prop="productBox" label="订单箱数" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="totalAmount" label="金额(元)" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="factoryName" label="生产厂家" show-overflow-tooltip align="center" min-width="150"
            fixed="right">
          </el-table-column>
        </el-table>
      </div>
      <footer style="text-align: center;margin-top: 20px;">
        <el-button type="primary" @click="clickOrderTaking">确认接单</el-button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      loading: false,
      shopName: '',//商超名称
      shopNum: '',//商超编号
      contactTel: '',//手机号码
      purchaseId: '',
      // 表格数据
      tableData: [],
    }
  },
  created() {
    this.purchaseId = this.$route.query.purchaseId
    this.getRequest()
  },
  methods: {
    // 请求详情列表
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendOrderInfo, {
        params: {
          purchaseId: this.$route.query.purchaseId,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.shopName = result.shopName
          this.shopNum = result.shopNum
          this.contactTel = result.contactTel
          this.tableData = result.purchaseProductVoList
          this.loading = false;
        }
      }).catch(() => {
        this.loading = false;
      })

    },
    // 确认接单
    clickOrderTaking() {
      this.$axios.post(this.$api.sendOrderConfirmOrder, this.purchaseId).then((res) => {
        const { code } = res.data
        if (code == 100) {
          this.$message({
            showClose: true,
            message: '恭喜你，接单成功！',
            type: 'success'
          });
          this.$router.go(-1)
        }
      }).catch((err) => {
        console.log(err);
      })

    }
  },
}
</script>

<style scoped lang='scss'>
.content {
  header {
    text-align: center;

    .info {
      display: flex;
      margin: 20px 0;
      justify-content: center;

      div {
        margin-right: 50px;
      }
    }
  }
}

.tableBox {
  overflow: auto;


  :deep .el-table--group::after,
  .el-table--border::after,
  .el-table::before {
    content: '';
    position: absolute;
    background-color: #EBEEF5;
    opacity: 0;
  }

  :deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px;
    /*滚动条宽度*/
    height: 4px;
    /*滚动条高度*/
  }

}
</style>
